/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href="/"
                target="_self"
              >
                MRB Inc.
              </a>
            </li>
          </ul>
        </nav>
        <div>
          <ul>
            <li>
              정승훈 대표 | 경남 양산시 동면 금오로 66, 4층 403호(세븐스톤빌딩)<br></br>
              사업자등록번호  423-27-01683 [사업자정보확인]<br></br>
              무역업고유번호: 10592294<br></br>
              통신판매업 신고: 제 2024-경남양산-0778 호
            </li>
          </ul>
        </div>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed by{" "}
          <a
            href="#"
            target="_self"
          >
            MRB
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
