import React from 'react';
import { Container, Row, Col } from 'reactstrap'; // Import Row and Col components
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import "../../assets/css/mrb.css";

const FIRST_IMAGE = {
  imageUrl: 'https://sirpi.wpengine.com/wp-content/uploads/2023/06/home-3-before.webp'
};
const SECOND_IMAGE = {
  imageUrl: 'https://sirpi.wpengine.com/wp-content/uploads/2023/06/home-3-after.webp'
};
const FIRST_IMAGE2 = {
  imageUrl: 'https://sirpi.wpengine.com/wp-content/uploads/2023/06/home-1-before-image.webp'
};
const SECOND_IMAGE2 = {
  imageUrl: 'https://sirpi.wpengine.com/wp-content/uploads/2023/06/home-1-after-image.webp'
};

/* Custom styles */
const delimiterIconStyles = {
  width: '40px',
  height: '40px',
  backgroundSize: 'cover',
  borderRadius: 'none',
  backgroundImage: "url(" + require("assets/img/m_logo3.png") + ")",
};

const inner_style = {
  paddingLeft: 10,
  paddingRight: 10,
};

const inner_style2 = {
  paddingLeft: 30,
  paddingRight: 30,
};

function BeforeAfter() {
  return (
    <div id="portpolio">
      <span className="mrb-title">당신은 내 피부상태를 얼마나 알고 계신가요?<br></br>피부 관리 전과 후의 변화를 경험하세요</span>
    <div style={inner_style}>
      {/* Make the layout responsive using Row and Col components */}
      <Row className="justify-content-between">
        <Col xs="12" md="6" className="mb-4"> {/* Display 12 columns on extra small screens, 6 on medium and larger screens */}
          <ReactBeforeSliderComponent delimiterIconStyles={delimiterIconStyles} firstImage={FIRST_IMAGE} secondImage={SECOND_IMAGE} />
        </Col>
        <Col xs="12" md="6" className="mb-4">
          <ReactBeforeSliderComponent delimiterIconStyles={delimiterIconStyles} firstImage={FIRST_IMAGE2} secondImage={SECOND_IMAGE2} />
        </Col>
      </Row>
      <Container className="mt-5">
        <h5 style={inner_style2}>
          <p>
            피부 트러블은 많은 이들에게 자신감을 떨어뜨리고 일상생활에 불편을 줄 수 있는 고민거리입니다. 얼굴에 생긴 작은 트러블 하나가 나의 이미지와 기분에 큰 영향을 미칠 수 있죠.
            하지만, 꾸준한 관리와 올바른 치료는 이러한 문제를 극복할 수 있는 해결책이 됩니다.
          </p>
          <p>
            위의 사진에서 보시다시피, 피부 관리 전과 후의 변화는 눈에 띄게 다릅니다. 트러블이 가득했던 피부는 점차 매끄럽고 건강한 상태로 회복되었습니다. 이러한 변화를 통해 피부 관리의 효과를 확실하게 느낄 수 있습니다.
            이처럼 꾸준한 관리와 치료는 단순한 미용이 아니라 건강한 피부와 자신감을 회복하는 길입니다.
          </p>
          <p>
            피부는 매일 다양한 외부 환경에 노출되며 지속적인 관심과 관리가 필요합니다. 피부 관리의 중요성을 잊지 마세요. 당신의 피부는 꾸준한 노력에 반응하며, 그 결과는 사진처럼 분명하게 나타날 것입니다.
            건강하고 아름다운 피부를 위해 오늘부터 피부 관리를 시작해보세요.
          </p>
        </h5>
      </Container>
    </div>
    </div>
  );
}

export default BeforeAfter;
