/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components
import "../../assets/css/mrb.css";
import background1 from "../../assets/img/header2.jpg";
function MainBanner() {
    const images = [
        
        { src: require("../../assets/img/mainbanner2_r.png"), text: "뷰티 비지니스 사업자를 위한\n또 하나의 솔루션" },
        { src: require("../../assets/img/mainbanner3_r.png"), text: "정전식 터치스크린 적용\nLED라이트 적용" },
        { src: require("../../assets/img/mainbanner4_r.png"), text: "4 Light Pictures\n14가지 피부 트러블 분석" },
        { src: require("../../assets/img/mainbanner5_r.png"), text: "광학기술과 머신러닝이 만나\n표준화된 분석결과 제공" },
        { src: require("../../assets/img/mainbanner6_r.png"), text: "피부 상태 맞춤\n퍼스널 케어 시스템" },
      ];

    return (
    <>
        <div className="main-banner-container">
            {images.map((image, index) => (
                <div key={index} className="main-banner-slide">
                    <img src={image.src} alt={`banner-${index + 1}`} className="banner-image" />
                    <div className="banner-text">{image.text}</div>
                </div>
            ))}
        </div>
    </>
  );
}

export default MainBanner;
