import React from "react";
import{
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Button,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function About() {
  return (
    <>
      <Card className="text-center" id="about">
        <CardBody>
         <CardTitle tag="h4">MRB is..</CardTitle>
         <img
            alt="..."
            src={require("assets/img/worldmap1.png")}
          ></img>
         <CardText><h5 className="description">
                소비자에게는 아름다움과 건강한 삶을
                뷰티 비지니스 사업자에게는 더 높은 부가가치 창출을
                제공해 드립니다.
              </h5>
              </CardText>
        </CardBody>
      </Card>
    </>
  );
}

export default About;
