import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/mrb-pic.css"; // 스타일은 별도의 CSS 파일로 관리


function News() {
  const images = [
    { src: require("../../assets/img/ph1.png"), text: "Pharmacy" },
    { src: require("../../assets/img/ph2.png"), text: "Pharmacy" },
    { src: require("../../assets/img/ph3.png"), text: "Pharmacy" },
    { src: require("../../assets/img/ph4.png"), text: "Pharmacy" },
    { src: require("../../assets/img/etc1.png"), text: "ETC" },
    { src: require("../../assets/img/etc2.png"), text: "ETC" },
    { src: require("../../assets/img/etc3.png"), text: "ETC" },
    { src: require("../../assets/img/etc4.png"), text: "ETC" },
    { src: require("../../assets/img/ev1.png"), text: "Event" },
    { src: require("../../assets/img/ev2.png"), text: "Event" },
    { src: require("../../assets/img/ev3.png"), text: "Event" },
    { src: require("../../assets/img/ev4.png"), text: "Event" },
  ];

  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 화면 크기에 따라 모바일 여부 결정
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    const container = containerRef.current;
    const scrollStep = isMobile ? container.offsetWidth / 2 : container.offsetWidth / 4; // 모바일에서는 2장씩, 데스크탑에서는 4장씩
    const maxScroll = container.scrollWidth - container.offsetWidth;
    let scrollAmount = 0;

    const intervalId = setInterval(() => {
      if (scrollAmount >= maxScroll) {
        scrollAmount = 0; // 끝에 도달하면 처음으로
      } else {
        scrollAmount += scrollStep;
      }
      container.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }, 5000); // 5초마다 한 묶음씩 이동

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <div id="news">
      <span className="news-title">News</span>
      <div className="news-container" ref={containerRef} id="news">
        {images.map((image, index) => (
          <div key={index} className="news-slide">
            <img src={image.src} alt={`news-${index + 1}`} className="news-image" />
            <div className="news-text">{image.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default News;